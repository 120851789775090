import InternalAPI from '@/app/baseAxios';
import { TimeMapper } from '@/misc/timeMapper';

/** @borrows InternalAPI */
export default class EventAndTipsRepository extends InternalAPI {
  constructor() {
    super();
    /** @type { string } */
    this.endpoint = 'v2/event_and_tips';
  }

  /**
   *
   * @param page
   * @param perPage
   * @param filter
   * @return { Promise<{data: ?EventAndTips[], err: Error}> }
   * @private
   */

  async getAll(page, perPage, filter, articleType, hashtag, search, isSuggested) {
    const t = new Date()
    const qDate = TimeMapper.SetFormatDate(t.toDateString(), false, ["-", "-", "-"], false, false).split("-").reverse().join("-")
    try {
      /** @type {AxiosResponse<Object>} */
      let url = `page=${page}&per_page=${perPage}&s=active&order_by=active_date&date=${qDate}&t=${filter}&article_info=${articleType}&is_suggested=true&h=${hashtag}&q=${search}`
      if (!isSuggested) { 
        url = `page=${page}&per_page=${perPage}&s=active&order_by=active_date&date=${qDate}&t=${filter}&article_info=${articleType}&h=${hashtag}&q=${search}`
      }
      const response = await this.$axios.get(`/${this.endpoint}/data?${url}`);
      if (response?.status === 200 && response?.data) {
        return { data: response.data.data, err: null, pagination: response.data.pagination };
      }
      return { data: null, err: null, pagination: null };
    } catch (error) {
      return { data: null, err: this.setErrorMessage(error.response?.data?.messages), pagination: null };
    }
  }

  /**
   *
   * @param params
   * @return { Promise<{data: ?EventAndTips[], err: Error}> }
   * @private
   */

  async get(params) {
    try {
      const response = await this.$axios.get(`/${this.endpoint}/${params}`);
      if (response?.status === 200 && response?.data) {
        return { data: response.data.data, err: null };
      }
      return { data: null, err: null };
    } catch (error) {
      return { data: null, err: this.setErrorMessage(error?.response?.data?.messages) };
    }
  }
}
