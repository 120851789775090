import EventAndTipsRepository from '../api';
import '../types';

class EventAndTipsUseCase {
  constructor() {
    /** @type {EventAndTipsRepository} */
    this.api = new EventAndTipsRepository();
  }

  /**
   *
   * @param page
   * @param perPage
   * @param filter
   * @return { Promise<{data: EventAndTips[], err: Error} }
   */

  async getAllProgram(page = 1, perPage = 10, filter, articleType = 'SELLER', hashtag = '', search = '', isSuggested = true) {
    const { data, err, pagination } = await this.api.getAll(page, perPage, filter, articleType, hashtag, search, isSuggested);
    return { data, err, pagination };
  }

  /**
   *
   * @param params
   * @return { Promise<{data: EventAndTipsDetail, err: Error} }
   */

  async getProgramDetail(params) {
    const { data, err } = await this.api.get(params);
    return { data, err };
  }
}

export default new EventAndTipsUseCase();
